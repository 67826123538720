
$base-unit:                     16px;
$base-font-family:              'Open Sans', sans-serif;
$base-line-height:              1.5;
$base-font-size:                3rem;
$header-height:                 80px;

$turquoise:                   #3BFDD4;
$orange:                      #FF9234;
$midnight:                    #393E46;
$off-white:                   #FBFCFD;
$error:                       #FF0033;

@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);
  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }
  @return $string;
}

@function url-encode($string) {
  $map: (
    "%": "%25",
    "<": "%3C",
    ">": "%3E",
    " ": "%20",
    "!": "%21",
    "*": "%2A",
    "'": "%27",
    '"': "%22",
    "(": "%28",
    ")": "%29",
    ";": "%3B",
    ":": "%3A",
    "@": "%40",
    "&": "%26",
    "=": "%3D",
    "+": "%2B",
    "$": "%24",
    ",": "%2C",
    "/": "%2F",
    "?": "%3F",
    "#": "%23",
    "[": "%5B",
    "]": "%5D"
  );
  $new: $string;
  @each $search, $replace in $map {
    $new: str-replace($new, $search, $replace);
  }
  @return $new;
}

@function inline-svg($string) {
  @return url('data:image/svg+xml;charset=utf-8,#{url-encode($string)}');
}

@mixin clear-fix() {

    &:after {
        content: '';
        display: table;
        clear: both;
    }
}
