@font-face {
	font-family: 'Min';
	src: url('../fonts/Min-Two.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Min';
	src: url('../fonts/Min-Two_1.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

h1,
h2,
h3,
h4 {
	margin: 0 0 $base-unit*2 0;
	line-height: 1.2;
	font-weight: 300;
	font-family: $base-font-family;
	font-weight: normal;
	font-style: normal;
}

h1 {
	font-size: 7.0rem;
	font-family: 'Min';
}

h2 {
	font-size: 4.0rem;
	font-family: 'Min';
}

h3 {
	font-size: 2.4rem;
}

h4 {	
	font-size: 2.0rem;
}


p  {
	font-size: 2.4rem;
	font-weight: 300;
	font-family: 'Roboto', sans-serif;	
	line-height: 1.25;
}